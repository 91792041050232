.link {
  display: flex;
  align-items: baseline;

  .linkText {
    white-space: normal;
  }

  .linkIcon {
    color: inherit;
  }
}

.link:visited .linkIcon {
  color: var(--system-purple);
}
