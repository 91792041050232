.container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-sm); /* more than absolute overlay and less than fixed overlay */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.fill {
  top: 0;
}

.quickLaunch {

  @mixin baseFontXs;

  box-sizing: border-box;
  border-top: var(--border-width) solid var(--system-grey-4);
  background-color: var(--primary-background-color);
}

.itemsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.noItems {
  justify-content: center;
}

@media print {
  .container {
    position: static;
  }
}
