.container {
  display: flex;
  padding: var(--space-sm) 0;
}

.title {

  @mixin baseFontMd;

  display: block;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-light-bold);
}

.subtitle {
  color: var(--secondary-text-color);
}
