:root,
[data-theme="light"] {
  --account-card-border-focus-color: var(--primary-accent-color);
}

.card {
  --account-card-title-color: var(--primary-accent-color);
  --account-card-item-gap: var(--space-xxs);
  --account-card-background-color: var(--primary-background-color);
  --account-card-transition: all 0.09s ease-out;
  --account-header-margin: var(--space-xxxs);
  --account-card-title-before-color: var(--primary-accent-color);
  --account-card-title-after-color: var(--secondary-text-color);
  --account-card-content-before-color: var(--primary-text-color);
  --account-card-content-after-color: var(--secondary-text-color);
  --account-card-footer-before-color: var(--secondary-text-color);
  --account-card-footer-after-color: var(--secondary-text-color);
  --account-card-drag-control-color: var(--secondary-text-color);
  --linked-account-indicator-color: var(--primary-accent-color);
  --linked-account-indicator-height: 5rem;

  position: relative;
  width: 100%;
  max-width: 100%;
  border: var(--border-width-lg) solid transparent;
  background-color: var(--account-card-background-color);
  transition: var(--account-card-transition);

  &:hover {
    --account-card-background-color: var(--secondary-background-color);
  }

  &:focus-visible {
    border-color: var(--account-card-border-focus-color);
    outline: none;
  }
}

.error {
  color: var(--system-red);
}

.isSelected {
  --account-card-background-color: var(--primary-accent-color);
  --account-card-title-color: var(--contrast-text-color);
  --account-card-title-before-color: var(--contrast-text-color);
  --account-card-title-after-color: var(--contrast-text-color);
  --account-card-content-before-color: var(--contrast-text-color);
  --account-card-content-after-color: var(--contrast-text-color);
  --account-card-footer-before-color: var(--contrast-text-color);
  --account-card-footer-after-color: var(--contrast-text-color);
  --account-card-drag-control-color: var(--contrast-text-color);

  &:hover {
    --account-card-background-color: var(--primary-accent-color);
  }
}

.header {
  display: flex;

  &:not(:last-child) {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    margin-bottom: var(--account-header-margin);
  }
}

.title {

  @mixin baseFontMd;

  @mixin textOverflow;

  margin-right: var(--account-card-item-gap);
  color: var(--account-card-title-color);
  font-weight: var(--font-weight-light-bold);
  letter-spacing: var(--base-letter-spacing-lg);
  text-align: left;
}

.isTitleMultiline {
  white-space: normal;

  span {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

.errorTitleBefore {
  margin-right: var(--account-card-item-gap);
  color: var(--system-red);
  font-size: var(--base-font-size-sm);
  letter-spacing: var(--base-letter-spacing);
  line-height: var(--base-line-height-sm);
}

.titleBefore {
  &:not(:empty) {
    margin-right: var(--account-card-item-gap);
    color: var(--account-card-title-before-color);
    font-size: var(--base-font-size-sm);
    letter-spacing: var(--base-letter-spacing);
    line-height: var(--base-line-height-sm);
  }
}

.titleAfter {
  max-width: 5.125rem;
  margin-left: auto;
  color: var(--account-card-title-after-color);
  font-size: var(--base-font-size-sm);
  letter-spacing: var(--base-letter-spacing-sm);
  text-align: right;
  white-space: nowrap; /* MPH-53191 */

  &ClipBox {
    display: block;
  }
}

.content {
  display: flex;
  align-items: flex-end;

  &:not(:last-child) {

    /* margin-bottom: var(--space-xxxxs); */
  }
}

.contentBefore {

  @mixin headerFontH6;

  color: var(--account-card-content-before-color);
  overflow-wrap: anywhere;
}

.contentAfter {
  margin-left: auto;
  color: var(--account-card-content-after-color);
  font-size: var(--base-font-size-sm);
  letter-spacing: var(--base-letter-spacing-sm);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footerBefore {

  @mixin textOverflow;

  margin-right: var(--account-card-item-gap);
  color: var(--account-card-footer-before-color);
  letter-spacing: var(--letter-spacing-h1);
  line-height: var(--base-line-height);
}

.isFooterBeforeMultiline {
  white-space: normal;
}

.footerAfter {
  margin-left: auto;

  &:not(:last-child) {
    margin-right: var(--account-card-item-gap);
  }
}

.dragHandleButton {
  display: inline-flex;

  /* reset button */
  padding: 0;
  border: none;
  background: none;
  cursor: grab;
}


.isCollapsed {
  --account-card-background-color: var(--primary-background-color);

  .header,
  .footer,
  .content {
    display: none;
  }

  &.isSelected {
    --account-card-background-color: var(--primary-background-color);
  }
}

[data-theme="dark"] {
  --account-card-border-focus-color: var(--system-white);

  .isCollapsed {
    --account-card-background-color: var(--primary-background-color);

    &.isSelected {
      --account-card-background-color: var(--primary-background-color);
    }
  }
}

@media (--device-mobile) {
  .card {
    user-select: none;
  }
}
