.title {

  @mixin headerFontH6;

  margin-bottom: var(--space);
}

.description {
  margin-bottom: var(--space-xs);
  color: var(--primary-text-color);
}

.content {
  padding-top: var(--space-xs);
}
