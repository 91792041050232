.listElement {
  --other-elements-padding: var(--space);

  padding-right: var(--other-elements-padding);
  padding-left: var(--other-elements-padding);
}

@media (--device-mobile) {
  .listElement {
    --other-elements-padding: var(--space-sm);
  }
}
