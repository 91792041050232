.slidesContainer {
  --horizontal-padding: var(--space);

  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-margin: 0 100px;
  scroll-padding-left: var(--horizontal-padding);
  scroll-padding-right: var(--horizontal-padding);

  /*
    The consistent gap and paddings let the 100%-width slides fill the container width fully with no other slides seen.
  */
  gap: var(--horizontal-padding);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 var(--horizontal-padding);
}

@media (--device-mobile) {
  .slidesContainer {
    --horizontal-padding: var(--space-sm);
  }
}

.slidesContainer::-webkit-scrollbar {
  display: none;
}

.slidesCenter {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.slidesNormal {
  display: grid;
  align-items: end;
}

.slide {
  scroll-snap-align: start;
  overflow: hidden;
}
