.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--space-xxs);
}

.dot {
  padding: var(--space-xxs) var(--space-xxxs);
  border-style: none;
  background-color: transparent;
}

.dot::after {
  --size: var(--space-xxs);

  content: "";
  display: block;
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: var(--system-white);
  transition: background-color 0.3s ease, width 0.3s ease;
  opacity: 0.5;
}

.activeDot::after {
  --size: var(--space-xxs);

  width: calc(4 * var(--size));
  opacity: 1;
}
