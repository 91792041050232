:root {
  --quick-launch-item-height: 4rem;
}

.button {
  flex: 1 1 auto; /* stretch buttons equally to remove dead zones between them; */
  min-width: unset;
  height: var(--quick-launch-item-height);
  color: var(--secondary-text-color);
  font-weight: var(--font-weight-normal);

  & > [class*="container"] {
    gap: 0;
  }
}

.selected {
  color: var(--primary-accent-color);

  & [class*="icon"] {
    color: inherit;
  }
}

.label {
  --line-clamp: 1;
}
